const apiConfig = 'https://bioma.cronogram.com.ar/back/';
const apiService = 'https://bioma.cronogram.com.ar/back/';
// const apiConfig = 'http://localhost/back/';
// const apiService = 'http://localhost/back/';
const apiWs = 'https://api.cronogram.com.ar/';


export { apiConfig, apiWs, apiService };


  
import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { apiConfig } from "../../services/apiConfig";
import { NavLink } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import { dataURLtoFile } from "./utils/dataURLtoFile";
import { Form, Row, Col, Button, FloatingLabel, Modal } from "react-bootstrap";
import CryptoJS from "crypto-js";
import "./userProfile.css";
import { useData } from "../../DataContext/DataContext";

export default function UsersProfile() {
  const { usuarios, user, getDataAll } = useData();

  const [filtro, setFiltro] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [nameUser, setNameUser] = useState("");
  const [message, setMessage] = useState(null);
  const [lastnameUser, setLastname] = useState("");
  const [phoneUser, setPhone] = useState("");
  const [emailUser, setEmail] = useState("");
  const [passwordUser, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [selectedOption, setSelectedOption] = useState("");
  const [rolList, setRolList] = useState([]);
  const [rol, setRol] = useState("");
  const inputRef1 = useRef("");
  const triggerFileSelectPopup1 = () => inputRef1.current.click();
  const [image1, setImage1] = useState(null);
  const [file1, setFile1] = useState(null);
  const [preview1, setPreview1] = useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onDownload = () => {
    generateDownload(image1, croppedArea);
  };

  useEffect(() => {
    axios.get(apiConfig + "profile/rol/listRol.php").then((getData) => {
      setRolList(getData.data);
    });
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    // As Base64 string
    // return canvas.toDataURL("image/jpeg");
    return canvas;
  };

  const generateDownload = async (imageSrc, crop) => {
    if (!crop || !imageSrc) {
      return;
    }
    const canvas = await getCroppedImg(imageSrc, crop);
    const canvasDataUrl = canvas.toDataURL("image/jpeg");
    const convertedUrlToFile = dataURLtoFile(
      canvasDataUrl,
      "cropped-image.jpeg"
    );
    setFile1(convertedUrlToFile);
    const reader = new FileReader();
    reader.readAsDataURL(convertedUrlToFile);
    reader.addEventListener("load", () => {
      setPreview1(reader.result);
      setShow1(false);
    });
  };

  function onErase(id) {
    axios.get(apiConfig + `profile/delete/delete.php?id=${id}`).then(() => {
      getDataAll();
    });
  }

  function onDelete(id) {
    axios.get(apiConfig + `profile/delete/index.php?id=${id}`).then(() => {
      getDataAll();
    });
  }

  function onActive(id) {
    axios.get(apiConfig + `profile/delete/active.php?id=${id}`).then(() => {
      getDataAll();
    });
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  function registerUser(event) {
    event.preventDefault();
    const registro = {
      name: nameUser,
      lastname: lastnameUser,
      phone: phoneUser,
      email: emailUser,
      password: passwordUser,
      id_user_rol: rol,
      id_business_office: user.id_business_office,
      id_business_unit: user.id_business_unit,
    };
    console.log(registro);
    axios
      .post(apiConfig + `profile/create/register.php`, registro)
      .then((res) => {
        let { data } = res;
        setShow(false);
        if (Number(data.success) === 1) {
          createImageNew(data.message);
          createRolNew(data.message);

          event.target.reset();
          if (message) {
            setMessage(false);
          }
        }
        setMessage(data.message);
      })
      .catch(function (res) {});
    getDataAll();
  }

  const createImageNew = (variable) => {
    const formData1 = new FormData();
    formData1.append("file", file1);
    formData1.append("id_user", variable);
    axios
      .post(apiConfig + `profile/create/image.php`, formData1)
      .then((res) => {
        let { data } = res;
        if (data.success && data.message) {
          if (data.success === 1) {
            setPreview1(null);
            setImage1(null);
            setFile1(null);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
      });
    getDataAll();
  };

  const createRolNew = (variable) => {
    const dataJson = selectedOption.value;
    const formData1 = new FormData();
    formData1.append("id_rol", dataJson);
    formData1.append("id_user", variable);
    formData1.append("id_business_office");
    axios
      .post(apiConfig + `profile/create/registerRol.php`, formData1)
      .then((res) => {
        setSelectedOption(null);
      })
      .catch(function (res) {
        console.log(res);
      });
    setSelectedOption(null);
  };

  const handleShowNew = () => {
    if (message) {
      setMessage(null);
    }
    clearImg();
    setSelectedOption(null);
    setShow(true);
  };

  const clearImg = () => {
    setImage1(null);
    setPreview1(null);
  };

  const colores = ["#ffc700", "#3e96fe", "#eb3f69", "#50cd89", "#736ced"];
  return (
    <>
      <div className="container mx-auto ">
        <div className="text-start">
          <NavLink exact to={"/config/"}>
            <h1 className="display-1">
              <BsIcons.BsArrowLeftShort className="app-color" />
            </h1>
          </NavLink>
        </div>
        <div className="card shadow mb-3" style={{ background: "#736CED" }}>
          <div className="card-body">
            <div className="text-center text-white">Usuarios</div>
          </div>
        </div>
        <div className="text-end">
          <div>
            <h1>
              <BsIcons.BsPlusCircleFill
                onClick={handleShowNew}
                style={{ color: "#736CED" }}
              />
            </h1>
          </div>
        </div>

        <div className="pt-2">
          <div className="input-group ">
            <input
              placeholder="Buscar"
              className="form-control form-control-sm"
              type="text"
              onChange={(e) => setFiltro(e.target.value)}
            />
            <span className="input-group-text" id="basic-addon2">
              <BsIcons.BsSearch />
            </span>
          </div>
        </div>
        <br />
        {usuarios
          .filter(
            (datos) =>
              datos.name
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase()) ||
              datos.last_name
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase()) ||
              datos.phone
                .toLocaleLowerCase()
                .includes(filtro.toLocaleLowerCase())
          )
          .map((data, index) => {
            return (
              <div key={index}>
                {Number(data.id_users_status) === 1 ? (
                  <div className="card shadow mb-3" key={data.id}>
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <span className="account-user-avatar ">
                            {data.avatar_url.length > 0 ? (
                              <>
                                <img
                                  src={
                                    apiConfig +
                                    "profile/image/" +
                                    data.avatar_url
                                  }
                                  alt="Cronogram"
                                  className="rounded-circle img-fluid"
                                />
                              </>
                            ) : (
                              <>
                                <div className="symbol symbol-35px symbol-circle">
                                  <div
                                    style={{
                                      width: "35px",
                                      height: "35px",
                                      background:
                                        colores[index % colores.length],
                                      color: "white",
                                      borderRadius: "50%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {data.name.substr(0, 1)}
                                    {data.last_name.substr(0, 1)}
                                  </div>
                                </div>
                              </>
                            )}
                          </span>
                        </div>
                        <div style={{ flex: 7 }} className="text-start">
                          {data.name},{data.last_name}
                        </div>
                        <div style={{ flex: 1 }}>
                          {Number(data.id_users_status) === 1 ? (
                            <div onClick={() => onDelete(data.id)}>
                              <h3>
                                <BsIcons.BsToggleOn />
                              </h3>
                            </div>
                          ) : (
                            <div onClick={() => onActive(data.id)}>
                              <h3>
                                <BsIcons.BsToggleOff />
                              </h3>
                            </div>
                          )}
                        </div>
                        <div style={{ flex: 1 }}>
                          <NavLink
                            exact
                            to={
                              "/profile/update/" +
                              encodeURIComponent(
                                CryptoJS.AES.encrypt(
                                  JSON.stringify(data),
                                  "clave_secreta"
                                ).toString()
                              )
                            }
                          >
                            <div className="">
                              <h3>
                                <BsIcons.BsThreeDotsVertical />
                              </h3>
                            </div>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card shadow mb-3" key={data.id}>
                    <div className="card-body">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <span className="account-user-avatar ">
                            <img
                              src={
                                apiConfig + "profile/image/" + data.avatar_url
                              }
                              alt="cronogram"
                              className="rounded-circle img-fluid"
                              style={{ filter: "grayscale(100%)" }}
                            />
                          </span>
                        </div>
                        <div style={{ flex: 7 }} className="text-start">
                          {data.name},{data.last_name}
                        </div>
                        <div style={{ flex: 1 }}>
                          {Number(data.id_users_status) === 1 ? (
                            <div onClick={() => onDelete(data.id)}>
                              <h3>
                                <BsIcons.BsToggleOn />
                              </h3>
                            </div>
                          ) : (
                            <div onClick={() => onActive(data.id)}>
                              <h3>
                                <BsIcons.BsToggleOff />
                              </h3>
                            </div>
                          )}
                        </div>
                        <div style={{ flex: 1 }}>
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Eliminar"
                            onClick={() => onErase(data.id)}
                          >
                            <h3>
                              <BsIcons.BsTrash />
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="container  mb-3">
            <div className="col text-center">
              {image1 || !image1 === "null" || !image1 === "" ? (
                <>
                  <img
                    src={preview1}
                    alt=""
                    className="rounded-circle account-user "
                    onClick={() => {
                      setPreview1(null);
                      setImage1(null);
                    }}
                  />
                </>
              ) : (
                <>
                  <input
                    type="file"
                    name="image1"
                    id="image1"
                    accept="image1/*"
                    capture="camera"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setImage1(reader.result);
                      };
                      reader.readAsDataURL(file);
                      setShow1(true);
                    }}
                    ref={inputRef1}
                    style={{ display: "none" }}
                  />
                  <h1>
                    <BsIcons.BsPlusCircleFill
                      onClick={triggerFileSelectPopup1}
                    />
                  </h1>
                </>
              )}
              <Modal show={show1} size="lg" onHide={handleClose1}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <div className="containers">
                    <div className="container-cropper">
                      <div className="cropper">
                        <Cropper
                          image={image1}
                          crop={crop}
                          zoom={zoom}
                          aspect={2 / 2}
                          cropShape="round"
                          showGrid={false}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                        />
                      </div>
                      <div className="slider">
                        <Slider
                          min={1}
                          max={3}
                          step={0.1}
                          value={zoom}
                          onChange={(e, zoom) => setZoom(zoom)}
                        />
                      </div>
                    </div>
                    <div className="container-buttons">
                      <div className="col-1"></div>
                      <button
                        className="col-3 btn app-button btn-lg"
                        onClick={onDownload}
                      >
                        <BsIcons.BsCheckLg />
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>

            <br />
            <br />
            <div className="center">
              <div className="col-12 pt-1">
                <Form onSubmit={registerUser}>
                  <Row>
                    <Col sm={6} className="mb-2">
                      <FloatingLabel label="Nombre">
                        <Form.Control
                          name="name"
                          type="text"
                          className="form-control"
                          onChange={(e) => setNameUser(e.target.value)}
                          pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
                          maxLength={20}
                          required
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} className="mb-2">
                      <FloatingLabel label="Apellido">
                        <Form.Control
                          name="lastname"
                          type="text"
                          className="form-control"
                          onChange={(e) => setLastname(e.target.value)}
                          pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
                          maxLength={20}
                          required
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Form.Group className="mb-2">
                    <FloatingLabel label="Teléfono">
                      <Form.Control
                        name="phone"
                        type="text"
                        className="form-control"
                        onChange={(e) => setPhone(e.target.value)}
                        minLength={10}
                        maxLength={10}
                        pattern="[0-9]+$"
                        required
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <FloatingLabel label="Correo electrónico">
                      <Form.Control
                        name="email"
                        type="email"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </FloatingLabel>
                  </Form.Group>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <FloatingLabel
                      controlId="floatingTotal1"
                      label="Contraseña"
                      className="col-10"
                    >
                      <Form.Control
                        name="password"
                        type={passwordType}
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                        minLength={6}
                        maxLength={6}
                        required
                      />
                    </FloatingLabel>
                    <div onClick={togglePassword}>
                      {passwordType === "password" ? (
                        <small>Mostrar</small>
                      ) : (
                        <small>Ocultar</small>
                      )}
                    </div>
                  </div>
                  <br />

                  <Form.Group className="mb-2">
                    <FloatingLabel controlId="floatingSelect" label="Roles">
                      <Form.Select
                        aria-label="Roles"
                        as="select"
                        name="id_user_rol"
                        onChange={(e) => setRol(e.target.value)}
                        required
                      >
                        <option value="">Elija una opción</option>
                        {rolList &&
                          rolList.map((a, i) => {
                            return (
                              <option key={i} value={a.id_user_rol}>
                                {a.nombre}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>

                  <br />
                  {message && (
                    <div
                      className="col-12 alert alert-primary"
                      onClick={() => setMessage(null)}
                    >
                      {message}
                    </div>
                  )}
                  <div className="d-grid mb-4">
                    <Button type="submit" className="btn app-button shadow">
                      Continuar
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

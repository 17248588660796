import * as BsIcons from "react-icons/bs";
import React, { useRef, useState } from "react";
import { apiConfig } from "../../services/apiConfig";
import axios from "axios";
import "./styles.css";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import { dataURLtoFile } from "./utils/dataURLtoFile";
import Modal from "react-bootstrap/Modal";
import "./Profile.css";
import { useData } from "../../DataContext/DataContext";

export default function Profile(props) {
  const { usuarios, user, getDataAll } = useData();

  const userObj = user.id;
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [dataError, setDataError] = useState("");
  const [dataSuccess, setDataSuccess] = useState("");
  const [mostrarComponente, setMostrarComponente] = useState(true);
  const [mostrarMsg, setMostrarMsg] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const inputRef = useRef("");

  const triggerFileSelectPopup = () => inputRef.current.click();
  const [image, setImage] = React.useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const actualizarDatos = () => {
    props.actualizarDatos(user.id);
  };

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
      setShow(true);
    }
  };

  const onDownload = () => {
    generateDownload(image, croppedArea);
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    // As Base64 string
    // return canvas.toDataURL("image/jpeg");
    return canvas;
  };
  const generateDownload = async (imageSrc, crop) => {
    if (!crop || !imageSrc) {
      return;
    }

    const canvas = await getCroppedImg(imageSrc, crop);
    const canvasDataUrl = canvas.toDataURL("image/jpeg");
    const convertedUrlToFile = dataURLtoFile(
      canvasDataUrl,
      "cropped-image.jpeg"
    );

    const formData = new FormData();
    formData.append("file", convertedUrlToFile);
    formData.append("usuario", user.id);

    axios
      .post(apiConfig + `profile/image.php`, formData)
      .then((res) => {
        let { data } = res;

        if (data.success && data.message) {
          if (data.success === 1) {
            getDataAll();
            setShow(false);
            setImage(null);
            actualizarDatos();
          }
        } else {
        }
      })
      .catch(function (res) {});

    //canvas.toBlob(
    // (blob) => {
    //    const previewUrl = window.URL.createObjectURL(blob);

    //    const anchor = document.createElement("a");
    //    anchor.download = "image.jpeg";
    //    anchor.href = URL.createObjectURL(blob);
    //    anchor.click();

    //    window.URL.revokeObjectURL(previewUrl);
    // },
    // "image/jpeg",
    // 0.66
    // );
  };

  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const togglePassword1 = () => {
    passwordType1 === "password"
      ? setPasswordType1("text")
      : setPasswordType1("password");
  };

  const initialState = {
    userInfo: {
      userId: userObj,
      password: "",
      password1: "",
    },
  };

  const [state, setState] = useState(initialState);

  const submitForm = (e) => {
    e.preventDefault();
    axios
      .post(apiConfig + `auth/changePassword.php`, state.userInfo)
      .then((res) => {
        let { data } = res;
        if (data.success && data.message) {
          if (data.success === 1) {
            setDataError("");
            setDataSuccess(data.message);
            setMostrarComponente(false);
            setMostrarMsg(true);
            actualizarDatos();
          }
        } else {
          setDataError(data.message);
          setMostrarMsg(true);
        }
      })
      .catch(function (res) {});
  };
  const colores = ["#ffc700", "#3e96fe", "#eb3f69", "#50cd89", "#736ced"];
  const onChangeValue = (e) => {
    if (mostrarMsg) {
      setMostrarMsg(false);
    }
    setState({
      ...state,
      userInfo: {
        ...state.userInfo,
        [e.target.name]: e.target.value,
      },
    });
  };

  let successMsg = "";
  let errorMsg = "";
  if (dataSuccess) {
    errorMsg = (
      <div className="alert alert-primary text-center " role="alert">
        {" "}
        {dataSuccess}
      </div>
    );
  }
  if (dataError) {
    successMsg = (
      <div className="alert alert-danger text-center " role="alert">
        {dataError}
      </div>
    );
  }

  const title = (
    <div className="avatar position-relative">
      {user.avatar_url.length > 0 ? (
        <>
          <img
            src={apiConfig + "profile/image/" + user.avatar_url}
            alt=""
            className="rounded-circle account-user "
            style={{
              borderRadius: "50px",
              border: "4px solid #ffffff42",
              cursor: "pointer",
            }}
            onClick={triggerFileSelectPopup}
          />
          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={onSelectFile}
            style={{ display: "none" }}
          />
        </>
      ) : (
        <>
          <div className="symbol symbol-35px symbol-circle">
            <div
              style={{
                width: "35px",
                height: "35px",
                background: colores[user.name.length % colores.length],
                color: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={triggerFileSelectPopup}
            >
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={onSelectFile}
                style={{ display: "none" }}
              />
              {user.name.substr(0, 1)}
              {user.last_name.substr(0, 1)}
            </div>
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: 30 }}>
        {title}

        <span style={{ fontSize: "1.2rem" }}>{user.name}</span>
      </div>
      <hr />
      <div className="container">
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Cambiar contraseña</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container  mb-3">
              <div className="row  pt-1 text-muted">
                <div className="row">
                  <form onSubmit={submitForm}>
                    <div className=" mb-2">
                      <small>Contraseña actual</small>
                      <div className="input-group mb-3">
                        <input
                          type={passwordType}
                          className="form-control"
                          name="password"
                          value={state.userInfo.password || ""}
                          onChange={onChangeValue}
                          minLength={6}
                          maxLength={10}
                          autoComplete="false"
                          required
                        />
                        <div
                          className="input-group-text app-click"
                          onClick={togglePassword}
                        >
                          {passwordType === "password" ? (
                            <small>Mostrar</small>
                          ) : (
                            <small>Ocultar</small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className=" mb-2">
                      <small>Nueva contraseña</small>
                      <div className="input-group mb-3">
                        <input
                          type={passwordType1}
                          className="form-control"
                          name="password1"
                          value={state.userInfo.password1 || ""}
                          onChange={onChangeValue}
                          minLength={6}
                          maxLength={10}
                          autoComplete="false"
                          required
                        />
                        <div
                          className="input-group-text app-click "
                          onClick={togglePassword1}
                        >
                          {passwordType1 === "password" ? (
                            <small>Mostrar</small>
                          ) : (
                            <small>Ocultar</small>
                          )}
                        </div>
                      </div>
                    </div>
                    {mostrarMsg && (
                      <div className="d-grid  mb-1">
                        {successMsg}
                        {errorMsg}
                      </div>
                    )}
                    <div></div>
                    {mostrarComponente && (
                      <>
                        <div className="d-grid mb-5 pt-3">
                          <button
                            type="submit"
                            className="btn app-button  shadow"
                          >
                            Actualizar
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajustar imagen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="containers">
            <div className="container-cropper">
              {image ? (
                <>
                  <div className="cropper">
                    <Cropper
                      image={image}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      cropShape="round"
                      showGrid={false}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                    />
                  </div>
                  <div className="slider">
                    <Slider
                      min={1}
                      max={3}
                      step={0.1}
                      value={zoom}
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                  </div>
                </>
              ) : null}
            </div>
            <div className="container-buttons">
              <div className="col-1"></div>
              <button
                className="col-3 btn btn-primary btn-lg"
                onClick={onDownload}
              >
                <BsIcons.BsCheckLg />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

import React, { useState, useEffect } from "react";
import "moment/locale/es";
import { Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import moment from "moment";
import { useData } from "../../DataContext/DataContext";
import TablaVentas from "../../components/TablaVentas/TablaVentas";

const Dashboard = () => {
  const { pagos, clientes, turnos, getDataAll } = useData();
  const [mes, setMes] = useState("");
  const [desde, setDesde] = useState("");
  const [hasta, setHasta] = useState("");
  const [loading, setLoading] = useState(false);
  const [girar, setGirar] = useState(false);

  const [turnosMes, setTurnosMes] = useState([]);

  const filtrarMes = (e, a) => {
    const datosFiltrados = e.filter(
      (x) => moment(x.fecha).format("YYYY-MM") === moment(a).format("YYYY-MM")
    );
    return datosFiltrados;
  };
  const handleChangeMes = (e) => {
    setMes(e.target.value);
    const valor = filtrarMes(turnos, e.target.value);
    setTurnosMes(valor);
  };

  useEffect(() => {
    const mesCorriente = moment().startOf("month").format("YYYY-MM");
    setMes(mesCorriente);
    const fecha = filtrarMes(turnos, mesCorriente);
    setTurnosMes(fecha);
    setLoading(true);
  }, []);

  const handleChangeDesde = (e) => {
    setDesde(e.target.value);
    let valor = filtrarDesdeHasta(turnos);
    setTurnosMes(valor);
  };
  const handleChangeHasta = (e) => {
    setHasta(e.target.value);
    let valor = filtrarDesdeHasta(turnos);
    setTurnosMes(valor);
  };

  const filtrarDesdeHasta = (e) => {
    if (desde && hasta) {
      const datosFiltrados = e.filter(
        (x) =>
          moment(x.fecha) >= moment(desde) && moment(x.fecha) <= moment(hasta)
      );
      return datosFiltrados;
    }
  };

  const colorMap = {
    1: "#fa5c7c", // "solicitado"
    2: "#0acf97", // "reservado"
  };

  // funciones de calculo

  const sinCobrar = (turno, pago) => {
    const lista = turno.filter(
      (x) => moment(x.fecha).format("YYYY-MM") === moment(mes).format("YYYY-MM")
    );
    const total = lista.reduce((acc, curr) => acc + Number(curr.precio), 0);

    const lista2 = pago.filter(
      (x) =>
        moment(x.fecha_pago).format("YYYY-MM") === moment(mes).format("YYYY-MM")
    );

    const total2 = lista2
      ? lista2.reduce((acc, curr) => acc + Number(curr.monto), 0)
      : 0;

    const valor = total - total2;

    const formato = Number(valor).toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 2,
    });
    return formato;
  };

  const totalCobrado = (pago) => {
    const lista = pago.filter(
      (x) =>
        moment(x.fecha_pago).format("YYYY-MM") === moment(mes).format("YYYY-MM")
    );

    const total = lista.reduce((acc, curr) => acc + Number(curr.monto), 0);

    const formato = Number(total).toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 2,
    });
    return formato;
  };

  const handleClickSpiner = () => {
    setGirar(true);
    getDataAll();
    setTimeout(() => {
      setGirar(false);
    }, 2000);
  };

  return (
    <>
      <nav
        className="navbar navbar-dark  top-fixed"
        style={{ backgroundColor: "rgb(115, 108, 237)" }}
      >
        <div className="container-fluid">
          <div className="navbar-brand text-white">Cronogram</div>

          <div className="d-flex text-white">
            <NavLink exact to={"/calendario"}>
              <div className="text-white">
                <BsIcons.BsCalendar2Event style={{ fontSize: "2rem" }} />
                <div>
                  <small style={{ fontSize: "0.7rem" }}>Agenda</small>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </nav>
      {loading ? (
        <div className="container">
          <div className="pt-4 text-center">
            <div className="row justify-content-end">
              <div className="col-md-3 col-sm-12 ">
                <h3>Periodo</h3>
                <input
                  type="month"
                  value={mes && mes}
                  className="form-control mb-3"
                  placeholder="Desde"
                  onChange={handleChangeMes}
                  max={hasta}
                />
              </div>
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-sm-6 col-md-3">
              <div
                className="card text-center text-white mb-3 mx-auto"
                style={{
                  width: "18rem",
                  height: "12rem",
                  background: "#e84c3d",
                }}
              >
                <div className="card-body d-flex align-items-center">
                  <div className="row">
                    <h1 className="display-3">
                      {turnosMes &&
                        turnosMes.filter((x) => Number(x.id_estado_pago) !== 2)
                          .length}
                    </h1>
                    <small>{turnos && sinCobrar(turnos, pagos)}</small>
                    <small>Pagos Pendientes</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div
                className="card text-center text-white mb-3 mx-auto"
                style={{
                  width: "18rem",
                  height: "12rem",
                  background: "#1abc9c",
                }}
              >
                <div className="card-body d-flex align-items-center">
                  <div className="row">
                    <h1 className="display-3">
                      {pagos &&
                        pagos.filter(
                          (x) =>
                            moment(x.fecha_pago).format("YYYY-MM") ===
                            moment(mes).format("YYYY-MM")
                        ).length}
                    </h1>
                    <small>{pagos && totalCobrado(pagos)}</small>
                    <small>Pagos Recibidos</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div
                className="card text-center text-white  mb-3 mx-auto"
                style={{
                  width: "18rem",
                  height: "12rem",
                  background: "#3598db",
                }}
              >
                <div className="card-body d-flex align-items-center">
                  <div className="row">
                    <h1 className="display-3">
                      {turnosMes &&
                        turnosMes.filter((x) => Number(x.id_estado) === 6)
                          .length}{" "}
                      /{" "}
                      {turnosMes &&
                        turnosMes.filter((x) => Number(x.id_estado) !== 6)
                          .length}{" "}
                    </h1>
                    <small>Prestaciones Realizadas</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div
                className="card text-center text-white  mb-3 mx-auto"
                style={{
                  width: "18rem",
                  height: "12rem",
                  background: "#9a59b5",
                }}
              >
                <div className="card-body d-flex align-items-center">
                  <div className="row">
                    <h1 className="display-3">{clientes && clientes.length}</h1>
                    <small>Clientes</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="text-end">
              <div
                onClick={handleClickSpiner}
                style={{ cursor: "pointer", height: "30px", color: "blue" }}
              >
                {!girar ? (
                  <small>Actualizar</small>
                ) : (
                  <small>
                    <Spinner />
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-12 col-sm-12 d-none d-sm-none d-md-block">
              <TablaVentas turnos={turnosMes} pagos={pagos} mes={mes} />
            </div>
            <div className="col-md-12 col-sm-12 pt-3 d-none d-sm-none d-md-block">
              <div className="card">
                <div className="card-body">
                  <h5>Pago Recibidos</h5>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nº Transacción</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Código</th>
                        <th scope="col">Total</th>
                        <th scope="col">Estado de pago</th>
                        <th scope="col">Metodo depago</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pagos &&
                        pagos
                          .filter(
                            (x) =>
                              moment(x.fecha_pago).format("YYYY-MM") ===
                              moment(mes).format("YYYY-MM")
                          )
                          .map((a, x) => (
                            <tr key={x}>
                              <th scope="row">{x + 1}</th>
                              <td>
                                {a.transaccion !== ""
                                  ? a.transaccion
                                  : a.transaccionMp + "MP"}
                              </td>
                              <td>
                                {turnos
                                  .filter(
                                    (x) => Number(x.id) === Number(a.id_turnos)
                                  )
                                  .map((x, i) => (
                                    <span key={i}>
                                      {x.nombre + ", " + x.apellido}
                                    </span>
                                  ))}
                              </td>

                              <td>
                                {moment(a.fecha_pago).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {turnos
                                  .filter(
                                    (x) => Number(x.id) === Number(a.id_turnos)
                                  )
                                  .map((x, i) => (
                                    <span key={i}>#{x.codigo}</span>
                                  ))}
                              </td>
                              <td className="text-end">
                                {Number(a.monto).toLocaleString("es-AR", {
                                  style: "currency",
                                  currency: "ARS",
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                              <td>
                                <small
                                  style={{
                                    background: "#0acf97",
                                    color: "white",
                                    borderRadius: "3px",
                                    padding: "5px",
                                  }}
                                >
                                  <span>Pagado</span>
                                </small>
                              </td>
                              <td>
                                {Number(a.id_medio_pago) === 1
                                  ? "Efectivo"
                                  : "Mercado Pago"}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="5" className="text-end">
                          <strong>Total</strong>
                        </td>
                        <td className="text-end">
                          {pagos && totalCobrado(pagos)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center pt-5">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default Dashboard;
